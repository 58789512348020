/* Navbar */
.chainboard-dot {
    display: block;
    width: 8px;
    height: 8px;
    background-color: #f2504b;
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
}

.chainboard-button {
    height: 48px;
    background-color: #f2504b;
}

/* Hero */
@keyframes fadeInToTop {
    from { opacity: 0; }
    to { opacity: 1; }
}

#hero-text-container {
    position: relative;
    z-index: 2;
}

.chainboard-hero-bg {
    position: relative;
}

.chainboard-hero-bg::before {
    content: '';
    z-index: 1;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-position: bottom right;
    background-repeat: no-repeat;
    animation: fadeInToTop 1s ease-in-out;
}

@media screen and (min-width: 600px) {
    .chainboard-hero-bg::before {
        background-image: url('./img/hero/chainboard-rediscover-world-400px.jpg');        
    }    
}

@media screen and (min-width: 768px) {
    .chainboard-hero-bg::before {
        background-image: url('./img/hero/chainboard-rediscover-world-800px.jpg');
    }    
}

@media screen and (min-width: 1110px) {
    .chainboard-hero-bg::before {
        background-image: url('./img/hero/chainboard-rediscover-world.jpg');
    }    
}

/* BSC */
.chainboard-bsc-text {
    letter-spacing: 0.55em;
}

.chainboard-bsc-logo {
    width: 195px;
    height: auto;
}

.chainboard-bsc-logo-price {
    width: 45px;
    height: auto;
}

.chainboard-bsc-separator {
    position: relative;
    width: 2px;
    height: 53px;
    border-radius: 2px;
    background-color: #d9d9d9;
}

/* Level Select */
.chainboard-icon-flash-button:hover, .chainboard-icon-flash-button:hover .chainboard-icon-flash, .chainboard-icon-flash-button-active, .chainboard-icon-flash-button-active .chainboard-icon-flash {
    color: #f2504b;
    fill: #f2504b;
}

/* Flash icon */
.chainboard-icon-flash {
    fill: #010206;
}

/* Snowboard size */
input[type='range'] {
    -webkit-appearance: none;
    width: 120px;
    height: 8px;
    background: #f2f2f2;
    border-radius: 4px;
    background-image: linear-gradient(#f2504b, #f2504b);
    background-size: 0% 100%;
    background-repeat: no-repeat;
}

input[type="range"]::-webkit-slider-thumb {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #f2504b;
    cursor: ew-resize;
    transition: background .3s ease-in-out;
}

input[type="range"]::-moz-range-thumb {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #f2504b;
    cursor: ew-resize;
    transition: background .3s ease-in-out;
}

input[type="range"]::-ms-thumb {
    -webkit-appearance: none;
    height: 24px;
    width: 24px;
    border-radius: 50%;
    background: #f2504b;
    cursor: ew-resize;
    transition: background .3s ease-in-out;
}
  
  input[type="range"]::-webkit-slider-thumb:hover {
    background: #f2504b;
  }
  
  input[type="range"]::-moz-range-thumb:hover {
    background: #f2504b;
  }
  
  input[type="range"]::-ms-thumb:hover {
    background: #f2504b;
  }

  input[type=range]::-webkit-slider-runnable-track  {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  input[type=range]::-moz-range-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }
  
  input[type="range"]::-ms-track {
    -webkit-appearance: none;
    box-shadow: none;
    border: none;
    background: transparent;
  }

/* Product Carousel */
.carousel-container, .carousel-wrapper, .carousel-container-wrapper {
    width: 100%;
}

.carousel-wrapper {
    position: relative;
}

.carousel-container-wrapper {
    overflow: hidden;
    height: 100%;
}

.carousel-content {
    transition: all 0.25s linear;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.carousel-content::-webkit-scrollbar, .carousel-content::-webkit-scrollbar {
    display: none;
}

.carousel-content > * {
    width: 100%;
    flex-shrink: 0;
    flex-grow: 1;
}

/* Button */
.chainboard-default-btn {
    max-width: 220px;
}

.chainboard-default-mini-btn {
    min-width: 220px;
}

/* Loader */
.spin-loader {
    display: inline-block;
    width: 20px;
    height: 20px;
    border: solid 2px #fff;
    border-top-color: #f2504b;
    border-radius: 50%;
    margin-right: 1em;
    animation: 1s ease-in-out infinite spinner;
    -webkit-animation: 1s ease-in-out infinite spinner;
}

@keyframes spinner {
    0% {
        transform: rotate(0deg);
        -webkit-transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
    }
}

/* Snowboard Size Calculation */
#snowboard-size-calculation-container {
    position: absolute;
    z-index: 999;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(1, 2, 6, 0.75);
}

#snowboard-size-calculation-wrapper {
    position: relative;
    background-color: #ffffff;
    border-radius: 24px;
    min-width: 800px;
}

#snowboard-size-calculation-close {
    position: absolute;
    top: 40px;
    right: 40px;
}

input[type='text'], input[type='number'], select {
    background-color: #f2f2f2;
    border-radius: 16px;
    padding: 6px 16px;
}

input[type='number'] {
    max-width: 56px;
}

input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button { 
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    margin: 0; 
}

/* Targets Firefox */
input[type='number'] {
    -moz-appearance: textfield;
}

input[type='submit'] {
    cursor: pointer;
}